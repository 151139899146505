import Vue from 'vue'
import App from './App.vue'
import Vant from 'vant';
import 'vant/lib/index.css';
import router from './router';
import api from '@/api';

Vue.use(Vant);
Vue.config.productionTip = false
Vue.prototype.$api = api;
new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
