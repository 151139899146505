import axios from 'axios';
import router from '@/router';
import { showLoading, hideLoading } from '@/utils';

const createConfig = {
    isLoading: true, // 是否需要Loading
    isToken: true, // 是否需要token
}

// 创建axios实例
const instance = axios.create({
    baseURL: process.env.VUE_APP_HOST,
    // timeout: 60000,
    headers: {'Content-Type': 'application/json;charset=UTF-8'},
});
// 添加请求拦截器
instance.interceptors.request.use(instanceConfig => {
    let config = {
        ...createConfig,
        ...instanceConfig
    }
    let tokenName = router.history.current.meta.tokenName || 'token';
    // 每次请求都带上本地的token
    if (localStorage.getItem(tokenName) && config.isToken !== false) {
        config.headers[tokenName] = localStorage.getItem(tokenName);
    }
    (config.isLoading !== false) && showLoading();
    return config
}, err => {
    hideLoading();
    return Promise.reject(err);
});

// 添加响应拦截器
instance.interceptors.response.use(res => {
    let { config } = res
    // token 失效问题，只能在对应的模块单独处理
    config.isLoading !== false && hideLoading();
    return res.data;
}, err => {
    hideLoading()
    return Promise.reject(err);
});
// 导出axios实例
export default instance
