import Vue from "vue"
import Router from "vue-router"

Vue.use(Router)

const routes = [
    {
        path: "/",
        name: "default",
        component: () => import("@/views/default/index.vue"),
        meta: {
            title: "测试",
        },
    },
    {
        path: "/test",
        name: "test",
        component: () => import("@/views/test/index.vue"),
        meta: {
            title: "测试",
        },
    }]
const router = new Router({
    routes,
    mode: "history",
})

export default router