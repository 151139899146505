import {
    Toast
  } from 'vant';
let toastInstance = null
// 显示loading
export function showLoading(options) {
  toastInstance = Toast.loading(options ? options : {
    duration: 0, // 持续展示 toast
    forbidClick: true,
    message: '加载中...',
  });
}

// 隐藏loading
export function hideLoading() {
  if (toastInstance) toastInstance.clear()
}