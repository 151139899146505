import http from '@/http';
import qs from 'qs';
import axios from 'axios';
export default {
    // 上传文件
    uploadFile: (file) => {
        console.log('uploadFile', file);
        let formData = new FormData();
        formData.append('file', file);
        return http.post(`/uploadFile`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    }
}
